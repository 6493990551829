import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure, FormLabel } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import { Suspenduser, getuserProfile } from "../Redux/Actions/AdminActions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import logo from "../Assets/Images/placeholdernew.png";
import { Deleteuser } from "../Redux/Actions/AdminActions";
import { toast } from "react-toastify";

export default function CustomerProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [popupImage, setPopupImage] = useState(null);

  const showPopup = (imageSrc) => {
    setPopupImage(imageSrc);
  };

  const hidePopup = () => {
    setPopupImage(null);
  };

  const [userdetails, setuserdetails] = useState([]);
  console.log(userdetails);
  const [show, setshow] = useState(false);
  const [showb, setshowb] = useState(false);

  useEffect(() => {
    const getdata = async () => {
      const data = await dispatch(getuserProfile({ id: location?.state?.id }));
      console.log(data);

      setuserdetails(data?.payload?.data?.data);
    };
    getdata();
  }, []);

  const handleSuspend = async (e) => {
    e.preventDefault();
    try {
      const data = await dispatch(
        Suspenduser({
          id: location?.state?.id,
          account_status: { account_status: 2 },
        })
      );
      console.log(data);
      if (data?.payload?.data?.status === 200) {
        toast.success(data?.payload?.data?.message);
        setshowb(false);
        navigate("/UserManagement");
      } else {
        toast.error(data?.payload?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const data = await dispatch(Deleteuser({ id: location?.state?.id }));
      console.log(data);
      if (data?.payload?.data?.status === 200) {
        toast.success(data?.payload?.data?.message);
        setshow(false);
        navigate("/UserManagement");
      } else {
        toast.error(data?.payload?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>User Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/UserManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            >
              <div className="cmn-btn">
                {/* <Link to="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.001 13.541C9.58698 13.541 9.25098 13.205 9.25098 12.791V0.75C9.25098 0.336 9.58698 0 10.001 0C10.415 0 10.751 0.336 10.751 0.75V12.791C10.751 13.205 10.415 13.541 10.001 13.541Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.0008 13.541C9.80183 13.541 9.60983 13.462 9.46983 13.32L6.55383 10.393C6.26183 10.099 6.26283 9.62403 6.55583 9.33203C6.84983 9.04003 7.32383 9.04003 7.61583 9.33403L10.0008 11.729L12.3858 9.33403C12.6778 9.04003 13.1518 9.04003 13.4458 9.33203C13.7388 9.62403 13.7398 10.099 13.4478 10.393L10.5318 13.32C10.3918 13.462 10.1998 13.541 10.0008 13.541Z"
                      fill="white"
                    />
                    <mask
                      id="mask0_1581_17087"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="4"
                      width="20"
                      height="15"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 4.73242H19.9998V18.4764H0V4.73242Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_1581_17087)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.575 18.4764H4.435C1.99 18.4764 0 16.4874 0 14.0414V9.15642C0 6.71642 1.985 4.73242 4.426 4.73242H5.367C5.781 4.73242 6.117 5.06842 6.117 5.48242C6.117 5.89642 5.781 6.23242 5.367 6.23242H4.426C2.812 6.23242 1.5 7.54342 1.5 9.15642V14.0414C1.5 15.6604 2.816 16.9764 4.435 16.9764H15.575C17.187 16.9764 18.5 15.6634 18.5 14.0514V9.16742C18.5 7.54842 17.183 6.23242 15.566 6.23242H14.634C14.22 6.23242 13.884 5.89642 13.884 5.48242C13.884 5.06842 14.22 4.73242 14.634 4.73242H15.566C18.011 4.73242 20 6.72242 20 9.16742V14.0514C20 16.4914 18.014 18.4764 15.575 18.4764Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  Export
                </Link> */}
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <Row className="justify-content-center">
                  <Col lg={12}>
                    <div className="customer-form-new mb-4">
                      <Container className="customer-form-inner m-0">
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <div className="image-upload">
                              <img
                                src={
                                  userdetails?.image
                                    ? `https://frizapp-api.bosselt.com/public/${userdetails?.image}`
                                    : logo
                                }
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Row>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Full name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type Here"
                                value={
                                  userdetails?.name ? userdetails?.name : "N/A"
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Type Here"
                                value={
                                  userdetails?.email
                                    ? userdetails?.email
                                    : "N/A"
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Phone</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type Here"
                                value={
                                  userdetails?.country_code &&
                                  userdetails?.phone_number
                                    ? `${userdetails.country_code} ${userdetails.phone_number}`
                                    : "N/A"
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Country</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type Here"
                                value={
                                  userdetails?.country
                                    ? userdetails?.country
                                    : "N/A"
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>State</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type Here"
                                value={
                                  userdetails?.state
                                    ? userdetails?.state
                                    : "N/A"
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>City</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type Here"
                                value={
                                  userdetails?.city ? userdetails?.city : "N/A"
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={12}>
                            <Form.Group className="">
                              <Form.Label>About</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Type Here"
                                value={
                                  userdetails?.about
                                    ? userdetails?.about
                                    : "N/A"
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* <Row> */}
              <Col lg={12}>
                <div className="user-profile-main mb-4 px-3  py-4 h-100">
                  <div className="property-main mt-0">
                    <h2 className="property">Basic details</h2>
                    <div className="active-default-links">
                      <Row>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Education:</h6>
                            <p>
                              {userdetails?.education === 0
                                ? "Associate's degree"
                                : userdetails?.education === 1
                                ? "Bachelor's degree"
                                : userdetails?.education === 2
                                ? "Doctorate degree"
                                : userdetails?.education === 3
                                ? "High school diploma"
                                : userdetails?.education === 4
                                ? "High school/GED"
                                : userdetails?.education === 5
                                ? "Master's degree"
                                : userdetails?.education === 6
                                ? "Professional certification"
                                : userdetails?.education === 7
                                ? "Some college, no degree"
                                : userdetails?.education === 8
                                ? "Vocational training"
                                : userdetails?.education === 9
                                ? "Others"
                                : userdetails?.education === 10
                                ? "Prefer not to say"
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Height (ft):</h6>
                            <p>
                              {userdetails?.height
                                ? userdetails?.height
                                : "N/A"}
                            </p>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Weight (Kgs):</h6>
                            <p>
                              {userdetails?.weight
                                ? userdetails?.weight
                                : "N/A"}{" "}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Religion:</h6>
                            <p>
                              {userdetails?.religion === 0
                                ? "Agnosticism"
                                : userdetails?.religion === 1
                                ? "Atheism"
                                : userdetails?.religion === 2
                                ? "Bahá'í Faith"
                                : userdetails?.religion === 3
                                ? "Buddhism"
                                : userdetails?.religion === 4
                                ? "Christianity"
                                : userdetails?.religion === 5
                                ? "Hinduism"
                                : userdetails?.religion === 6
                                ? "Islam"
                                : userdetails?.religion === 7
                                ? "Jainism"
                                : userdetails?.religion === 8
                                ? "Judaism"
                                : userdetails?.religion === 9
                                ? "No religion"
                                : userdetails?.religion === 10
                                ? "Other (Specify)"
                                : userdetails?.religion === 11
                                ? "Shintoism"
                                : userdetails?.religion === 12
                                ? "Sikhism"
                                : userdetails?.religion === 13
                                ? "Spiritual but not religious"
                                : userdetails?.religion === 14
                                ? "Taoism"
                                : userdetails?.religion === 15
                                ? "Prefer not to say"
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Language spoken:</h6>
                            <p>
                              {userdetails?.language_spoken
                                ?.map((res) => {
                                  return res === 0
                                    ? "Arabic"
                                    : res === 1
                                    ? "Chinese (Mandarin)"
                                    : res === 2
                                    ? "English"
                                    : res === 3
                                    ? "French"
                                    : res === 4
                                    ? "German"
                                    : res === 5
                                    ? "Hindi"
                                    : res === 6
                                    ? "Japanese"
                                    : res === 7
                                    ? "Portuguese"
                                    : res === 8
                                    ? "Russian"
                                    : res === 9
                                    ? "Spanish"
                                    : "N/A";
                                })
                                .join(", ")}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Body type:</h6>
                            <p>
                              {userdetails?.body_type == 0
                                ? "Athletic"
                                : userdetails?.body_type == 1
                                ? "Average"
                                : userdetails?.body_type == 2
                                ? "Bodybuilder"
                                : userdetails?.body_type == 3
                                ? "Curvy"
                                : userdetails?.body_type == 4
                                ? "Full-figured"
                                : userdetails?.body_type == 5
                                ? "Muscular"
                                : userdetails?.body_type == 6
                                ? "Petite"
                                : userdetails?.body_type == 7
                                ? "Slim"
                                : userdetails?.body_type === 8
                                ? "Stocky"
                                : userdetails?.body_type == 9
                                ? "Thin"
                                : userdetails?.body_type == 10
                                ? "Prefer not to say"
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Ethnicity:</h6>
                            <p>
                              {userdetails?.ethnicity === 0
                                ? "African American"
                                : userdetails?.ethnicity === 1
                                ? "Asian"
                                : userdetails?.ethnicity === 2
                                ? "Caucasian"
                                : userdetails?.ethnicity === 3
                                ? "Hispanic/Latino"
                                : userdetails?.ethnicity === 4
                                ? "Middle Eastern"
                                : userdetails?.ethnicity === 5
                                ? "Mixed"
                                : userdetails?.ethnicity === 6
                                ? "Native American/Indigenous"
                                : userdetails?.ethnicity === 7
                                ? "Other"
                                : userdetails?.ethnicity === 8
                                ? "Prefer not to say"
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Drink:</h6>
                            <p>
                              {userdetails?.drink == 0
                                ? "No"
                                : userdetails?.drink == 1
                                ? "Yes"
                                : userdetails?.drink == 2
                                ? "Sometimes"
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Smoke:</h6>
                            <p>
                              {userdetails?.smoke == 0
                                ? "Sometimes"
                                : userdetails?.smoke == 1
                                ? "Yes"
                                : userdetails?.smoke == 3
                                ? "No"
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Nationality :</h6>
                            <p>
                              {userdetails?.nationality === 0
                                ? "Afghan"
                                : userdetails?.nationality === 1
                                ? "Albanian"
                                : userdetails?.nationality === 2
                                ? "Algerian"
                                : userdetails?.nationality === 3
                                ? "Andorran"
                                : userdetails?.nationality === 4
                                ? "Angolan"
                                : userdetails?.nationality === 5
                                ? "Antiguan"
                                : userdetails?.nationality === 6
                                ? "Argentine"
                                : userdetails?.nationality === 7
                                ? "Armenian"
                                : userdetails?.nationality === 8
                                ? "Australian"
                                : userdetails?.nationality === 9
                                ? "Austrian"
                                : userdetails?.nationality === 10
                                ? "Azerbaijani"
                                : userdetails?.nationality === 11
                                ? "Bahamian"
                                : userdetails?.nationality === 12
                                ? "Bahraini"
                                : userdetails?.nationality === 13
                                ? "Bangladeshi"
                                : userdetails?.nationality === 14
                                ? "Barbadian"
                                : userdetails?.nationality === 15
                                ? "Belarusian"
                                : userdetails?.nationality === 16
                                ? "Belgian"
                                : userdetails?.nationality === 17
                                ? "Belizean"
                                : userdetails?.nationality === 18
                                ? "Beninese"
                                : userdetails?.nationality === 19
                                ? "Bhutanese"
                                : userdetails?.nationality === 20
                                ? "Bolivian"
                                : userdetails?.nationality === 21
                                ? "Bosnian"
                                : userdetails?.nationality === 22
                                ? "Brazilian"
                                : userdetails?.nationality === 23
                                ? "British"
                                : userdetails?.nationality === 24
                                ? "Bruneian"
                                : userdetails?.nationality === 25
                                ? "Bulgarian"
                                : userdetails?.nationality === 26
                                ? "Burundian"
                                : userdetails?.nationality === 27
                                ? "Cambodian"
                                : userdetails?.nationality === 28
                                ? "Cameroonian"
                                : userdetails?.nationality === 29
                                ? "Canadian"
                                : userdetails?.nationality === 30
                                ? "Cape Verdean"
                                : userdetails?.nationality === 31
                                ? "Central African"
                                : userdetails?.nationality === 32
                                ? "Chadian"
                                : userdetails?.nationality === 33
                                ? "Chilean"
                                : userdetails?.nationality === 34
                                ? "Chinese"
                                : userdetails?.nationality === 35
                                ? "Colombian"
                                : userdetails?.nationality === 36
                                ? "Comorian"
                                : userdetails?.nationality === 37
                                ? "Congolese (Congo-Brazzaville)"
                                : userdetails?.nationality === 38
                                ? "Congolese (Congo-Kinshasa)"
                                : userdetails?.nationality === 39
                                ? "Costa Rican"
                                : userdetails?.nationality === 40
                                ? "Croatian"
                                : userdetails?.nationality === 41
                                ? "Cuban"
                                : userdetails?.nationality === 42
                                ? "Cypriot"
                                : userdetails?.nationality === 43
                                ? "Czech"
                                : userdetails?.nationality === 44
                                ? "Danish"
                                : userdetails?.nationality === 45
                                ? "Djiboutian"
                                : userdetails?.nationality === 46
                                ? "Dominican"
                                : userdetails?.nationality === 47
                                ? "Dominican (Republic)"
                                : userdetails?.nationality === 48
                                ? "Ecuadorian"
                                : userdetails?.nationality === 49
                                ? "Egyptian"
                                : userdetails?.nationality === 50
                                ? "Emirati"
                                : userdetails?.nationality === 51
                                ? "English"
                                : userdetails?.nationality === 52
                                ? "Equatorial Guinean"
                                : userdetails?.nationality === 53
                                ? "Eritrean"
                                : userdetails?.nationality === 54
                                ? "Estonian"
                                : userdetails?.nationality === 55
                                ? "Ethiopian"
                                : userdetails?.nationality === 56
                                ? "Fijian"
                                : userdetails?.nationality === 57
                                ? "Filipino"
                                : userdetails?.nationality === 58
                                ? "Finnish"
                                : userdetails?.nationality === 59
                                ? "French"
                                : userdetails?.nationality === 60
                                ? "Gabonese"
                                : userdetails?.nationality === 61
                                ? "Gambian"
                                : userdetails?.nationality === 62
                                ? "Georgian"
                                : userdetails?.nationality === 63
                                ? "German"
                                : userdetails?.nationality === 64
                                ? "Ghanaian"
                                : userdetails?.nationality === 65
                                ? "Greek"
                                : userdetails?.nationality === 66
                                ? "Grenadian"
                                : userdetails?.nationality === 67
                                ? "Guatemalan"
                                : userdetails?.nationality === 68
                                ? "Guinean"
                                : userdetails?.nationality === 69
                                ? "Guinea-Bissauan"
                                : userdetails?.nationality === 70
                                ? "Guyanese"
                                : userdetails?.nationality === 71
                                ? "Haitian"
                                : userdetails?.nationality === 72
                                ? "Honduran"
                                : userdetails?.nationality === 73
                                ? "Hungarian"
                                : userdetails?.nationality === 74
                                ? "Icelandic"
                                : userdetails?.nationality === 75
                                ? "Indian"
                                : userdetails?.nationality === 76
                                ? "Indonesian"
                                : userdetails?.nationality === 77
                                ? "Iranian"
                                : userdetails?.nationality === 78
                                ? "Iraqi"
                                : userdetails?.nationality === 79
                                ? "Irish"
                                : userdetails?.nationality === 80
                                ? "Israeli"
                                : userdetails?.nationality === 81
                                ? "Italian"
                                : userdetails?.nationality === 82
                                ? "Ivorian (Ivory Coast)"
                                : userdetails?.nationality === 83
                                ? "Jamaican"
                                : userdetails?.nationality === 84
                                ? "Japanese"
                                : userdetails?.nationality === 85
                                ? "Jordanian"
                                : userdetails?.nationality === 86
                                ? "Kazakh"
                                : userdetails?.nationality === 87
                                ? "Kenyan"
                                : userdetails?.nationality === 88
                                ? "Kiribati"
                                : userdetails?.nationality === 89
                                ? "Korean (North)"
                                : userdetails?.nationality === 90
                                ? "Korean (South)"
                                : userdetails?.nationality === 91
                                ? "Kuwaiti"
                                : userdetails?.nationality === 92
                                ? "Kyrgyz"
                                : userdetails?.nationality === 93
                                ? "Laotian"
                                : userdetails?.nationality === 94
                                ? "Latvian"
                                : userdetails?.nationality === 95
                                ? "Lebanese"
                                : userdetails?.nationality === 96
                                ? "Lesotho"
                                : userdetails?.nationality === 97
                                ? "Liberian"
                                : userdetails?.nationality === 98
                                ? "Libyan"
                                : userdetails?.nationality === 99
                                ? "Liechtenstein"
                                : userdetails?.nationality === 100
                                ? "Lithuanian"
                                : userdetails?.nationality === 101
                                ? "Luxembourgish"
                                : userdetails?.nationality === 102
                                ? "Macedonian"
                                : userdetails?.nationality === 103
                                ? "Madagascan"
                                : userdetails?.nationality === 104
                                ? "Malawian"
                                : userdetails?.nationality === 105
                                ? "Malaysian"
                                : userdetails?.nationality === 106
                                ? "Maldivian"
                                : userdetails?.nationality === 107
                                ? "Malian"
                                : userdetails?.nationality === 108
                                ? "Maltese"
                                : userdetails?.nationality === 109
                                ? "Marshallese"
                                : userdetails?.nationality === 110
                                ? "Mauritian"
                                : userdetails?.nationality === 111
                                ? "Mauritanian"
                                : userdetails?.nationality === 112
                                ? "Mexican"
                                : userdetails?.nationality === 113
                                ? "Micronesian"
                                : userdetails?.nationality === 114
                                ? "Moldovan"
                                : userdetails?.nationality === 115
                                ? "Monaco"
                                : userdetails?.nationality === 116
                                ? "Mongolian"
                                : userdetails?.nationality === 117
                                ? "Montenegrin"
                                : userdetails?.nationality === 118
                                ? "Moroccan"
                                : userdetails?.nationality === 119
                                ? "Mozambican"
                                : userdetails?.nationality === 120
                                ? "Namibian"
                                : userdetails?.nationality === 121
                                ? "Nauruan"
                                : userdetails?.nationality === 122
                                ? "Nepalese"
                                : userdetails?.nationality === 123
                                ? "New Zealand"
                                : userdetails?.nationality === 124
                                ? "Nicaraguan"
                                : userdetails?.nationality === 125
                                ? "Nigerien"
                                : userdetails?.nationality === 126
                                ? "Nigerian"
                                : userdetails?.nationality === 127
                                ? "Niuean"
                                : userdetails?.nationality === 128
                                ? "Norwegian"
                                : userdetails?.nationality === 129
                                ? "Omani"
                                : userdetails?.nationality === 130
                                ? "Pakistani"
                                : userdetails?.nationality === 131
                                ? "Palauan"
                                : userdetails?.nationality === 132
                                ? "Panamanian"
                                : userdetails?.nationality === 133
                                ? "Papua New Guinean"
                                : userdetails?.nationality === 134
                                ? "Paraguayan"
                                : userdetails?.nationality === 135
                                ? "Peruvian"
                                : userdetails?.nationality === 136
                                ? "Polish"
                                : userdetails?.nationality === 137
                                ? "Portuguese"
                                : userdetails?.nationality === 138
                                ? "Qatari"
                                : userdetails?.nationality === 139
                                ? "Romanian"
                                : userdetails?.nationality === 140
                                ? "Russian"
                                : userdetails?.nationality === 141
                                ? "Rwandan"
                                : userdetails?.nationality === 142
                                ? "Saint Kitts and Nevis"
                                : userdetails?.nationality === 143
                                ? "Saint Lucia"
                                : userdetails?.nationality === 144
                                ? "Saint Vincent and the Grenadines"
                                : userdetails?.nationality === 145
                                ? "Samoan"
                                : userdetails?.nationality === 146
                                ? "San Marinese"
                                : userdetails?.nationality === 147
                                ? "Sao Tomean"
                                : userdetails?.nationality === 148
                                ? "Saudi"
                                : userdetails?.nationality === 149
                                ? "Senegalese"
                                : userdetails?.nationality === 150
                                ? "Serbian"
                                : userdetails?.nationality === 151
                                ? "Seychellois"
                                : userdetails?.nationality === 152
                                ? "Sierra Leonean"
                                : userdetails?.nationality === 153
                                ? "Singaporean"
                                : userdetails?.nationality === 154
                                ? "Slovak"
                                : userdetails?.nationality === 155
                                ? "Slovenian"
                                : userdetails?.nationality === 156
                                ? "Solomon Islander"
                                : userdetails?.nationality === 157
                                ? "Somali"
                                : userdetails?.nationality === 158
                                ? "South African"
                                : userdetails?.nationality === 159
                                ? "South Sudanese"
                                : userdetails?.nationality === 160
                                ? "Spanish"
                                : userdetails?.nationality === 161
                                ? "Sri Lankan"
                                : userdetails?.nationality === 162
                                ? "Sudanese"
                                : userdetails?.nationality === 163
                                ? "Surinamese"
                                : userdetails?.nationality === 164
                                ? "Swazi"
                                : userdetails?.nationality === 165
                                ? "Swedish"
                                : userdetails?.nationality === 166
                                ? "Swiss"
                                : userdetails?.nationality === 167
                                ? "Syrian"
                                : userdetails?.nationality === 168
                                ? "Taiwanese"
                                : userdetails?.nationality === 169
                                ? "Tajik"
                                : userdetails?.nationality === 170
                                ? "Tanzanian"
                                : userdetails?.nationality === 171
                                ? "Thai"
                                : userdetails?.nationality === 172
                                ? "Togolese"
                                : userdetails?.nationality === 173
                                ? "Tongan"
                                : userdetails?.nationality === 174
                                ? "Trinidadian"
                                : userdetails?.nationality === 175
                                ? "Tunisian"
                                : userdetails?.nationality === 176
                                ? "Turkish"
                                : userdetails?.nationality === 177
                                ? "Turkmen"
                                : userdetails?.nationality === 178
                                ? "Tuvaluan"
                                : userdetails?.nationality === 179
                                ? "Ugandan"
                                : userdetails?.nationality === 180
                                ? "Ukrainian"
                                : userdetails?.nationality === 181
                                ? "Uruguayan"
                                : userdetails?.nationality === 182
                                ? "Uzbek"
                                : userdetails?.nationality === 183
                                ? "Vanuatu"
                                : userdetails?.nationality === 184
                                ? "Venezuelan"
                                : userdetails?.nationality === 185
                                ? "Vietnamese"
                                : userdetails?.nationality === 186
                                ? "Yemeni"
                                : userdetails?.nationality === 187
                                ? "Zambian"
                                : userdetails?.nationality === 188
                                ? "Zimbabwean"
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Pets:</h6>
                            <p>
                              {userdetails?.pets === 0
                                ? "No"
                                : userdetails?.pets === 1
                                ? "Yes"
                                : userdetails?.pets === 2
                                ? "Prefer not to say"
                                : "N/A"}
                            </p>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Looking for </h6>
                            <p>
                              {userdetails?.looking_for_soulmate == 0
                                ? "Friendship"
                                : userdetails?.looking_for_soulmate == 2
                                ? "Fun with another Partner"
                                : userdetails?.looking_for_soulmate == 3
                                ? "Serious Relationship"
                                : "N/A"}
                            </p>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Occupation</h6>
                            <p>
                              {userdetails?.basic_occupation == 1
                                ? "Technology and IT"
                                : userdetails?.basic_occupation == 2
                                ? "Healthcare"
                                : userdetails?.basic_occupation == 3
                                ? "Education"
                                : userdetails?.basic_occupation == 4
                                ? "Marketing and Advertising"
                                : userdetails?.basic_occupation == 5
                                ? "Finance and Accounting"
                                : userdetails?.basic_occupation == 6
                                ? "Legal Sector"
                                : userdetails?.basic_occupation == 7
                                ? "Human Resources"
                                : userdetails?.basic_occupation == 8
                                ? "Engineering"
                                : userdetails?.basic_occupation == 9
                                ? "Fashion and Design"
                                : userdetails?.basic_occupation == 10
                                ? "Retail and Commerce"
                                : userdetails?.basic_occupation == 11
                                ? "Manufacturing and Production"
                                : userdetails?.basic_occupation == 12
                                ? "Food and Restaurant Industry"
                                : userdetails?.basic_occupation == 13
                                ? "Bars and Cafés"
                                : userdetails?.basic_occupation == 14
                                ? "Tourism and Hospitality"
                                : userdetails?.basic_occupation == 15
                                ? "Media and Communication"
                                : userdetails?.basic_occupation == 16
                                ? "Energy and Environment"
                                : userdetails?.basic_occupation == 17
                                ? "Logistics and Transportation"
                                : userdetails?.basic_occupation == 18
                                ? "Real Estate Sector"
                                : userdetails?.basic_occupation == 19
                                ? "Art and Culture"
                                : userdetails?.basic_occupation == 20
                                ? "Cosmetics and Beauty"
                                : userdetails?.basic_occupation == 21
                                ? "Research and Development"
                                : userdetails?.basic_occupation == 22
                                ? "Automotive Industry"
                                : userdetails?.basic_occupation == 23
                                ? "Domestic Helper"
                                : userdetails?.basic_occupation == 24
                                ? "Education / Academic"
                                : userdetails?.basic_occupation == 25
                                ? "Construction / Trades"
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Marital status</h6>
                            <p>
                              {userdetails?.marital_status === 0
                                ? "Divorced"
                                : userdetails?.marital_status === 1
                                ? "Married"
                                : userdetails?.marital_status === 2
                                ? "Single"
                                : userdetails?.marital_status === 3
                                ? "Widowed"
                                : userdetails?.marital_status === 5
                                ? "Prefer not to say"
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <div className="hobbies-interests">
                        <h2>Hobbies and Interests</h2>
                        <ul>
                          {userdetails?.interest_hobbies?.map((res, index) => {
                            return <li>{res?.title || "N/A"}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* </Figure.Caption> */}
                </div>
              </Col>
            </Row>
            {/* </div> */}
          </Col>
          <Col lg={4}>
            <Row>
              <Col lg={12}>
                <div className="user-profile-main mb-4 px-3 py-4 ">
                  <div className="property-main mt-0">
                    <h2 className="property mb-3">Photos</h2>
                    {/* <div className="off-treatements">
                          <ul>
                            <li>Aesthetic Clinic </li>
                            <li>All Treatments </li>
                            <li>Body </li>
                            <li>Brow Treatments </li>
                            <li>Barbers </li>
                            <li>Facial Treatments </li>

                            <li>Holistic Therapies </li>
                            <li>Hair Treatments </li>
                          </ul>
                        </div> */}
                    <Row>
                      {userdetails?.images?.map((imgs, index) => {
                        return (
                          <Col lg={4} className="mb-3">
                            <img
                              className="profile-add-img"
                              src={`https://frizapp-api.bosselt.com/public/${imgs}`}
                              alt={`Image ${index}`}
                              style={{ height: 100, width: 100 }}
                              onClick={() =>
                                showPopup(
                                  `https://frizapp-api.bosselt.com/public/${imgs}`
                                )
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="user-profile-main mb-4 px-3 py-4 ">
                  <div className="property-main mt-0">
                    <h2 className="property mb-3">Identity</h2>
                    <Row className="active-default-links">
                      <h3>Company</h3>
                      <Col lg={12} className="mb-4">
                        <h6>Company-Name</h6>
                        <p>dummy text</p>
                      </Col>
                      <Col lg={12} className="mb-4">
                        <h6>Website Link</h6>
                        <p>dummy text</p>
                      </Col>
                      <Col lg={12} className="mb-4">
                        <h6>Address</h6>
                        <p>dummy text</p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg={6} className="mb-5">
            <div className="user-profile-main px-3 py-4 h-100">
              <Row>
                <Col lg={12}>
                  <div className="property-main mt-0">
                    <h2 className="property">Delete Account</h2>
                    <Figure.Caption className="m-0 mt-2">
                      <p>
                        Once you delete your account, you can not retrieve the
                        account. Please be certain.
                      </p>
                    </Figure.Caption>
                    <div class="dlt-ac-btn mt-4" style={{ cursor: "pointer" }}>
                      <a onClick={() => setshow(true)}>Delete Account</a>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} className="mb-5">
            <div className="user-profile-main px-3 py-4 h-100">
              <Row>
                <Col lg={12}>
                  <div className="property-main mt-0">
                    <h2 className="property">Suspend account</h2>
                    <Figure.Caption className="m-0 mt-2">
                      <p>
                        You will not be able to receive messages, notifications
                        for up to 24hours.
                      </p>
                    </Figure.Caption>
                    <div class="dlt-ac-btn mt-4" style={{ cursor: "pointer" }}>
                      <a onClick={() => setshowb(true)}>Suspend Account</a>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showb} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to suspend this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleSuspend}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowb(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      {popupImage && (
        <div id="popup" className="popup">
          <span className="close" onClick={hidePopup}>
            &times;
          </span>
          <img src={popupImage} alt="Popup" />
        </div>
      )}
    </Layout>
  );
}
